$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

var Lang = function () {
    return {
        init: function () {
            this.bindChoose()
            this.normalizeUrls()

        },
        normalizeUrls: function () {
            var current_url = $('#langs').find('li.active').data('lang');

            console.log(current_url)

            var urls = $('a');
            urls.each(function () {
                var url = new Url($(this).attr('href'));

            })
//            console.log(urls);
        },

        bindChoose: function () {
            $('#langs').find('li').on('click', function () {
                $(this).siblings().removeClass('active')
                $(this).addClass('active')


                var chosen_lang = $(this).data('lang')

                var url = new Url;

                if (chosen_lang === 'cyr') {
                    delete url.query.lang;
                } else {
                    url.query.lang = chosen_lang;
                }
                console.log(url.toString())

                window.location.replace(url);




//                var data = {
//                    'lang': $(this).data('lang')
//                }
//                
//                $.ajax({
//                    type: 'POST',
//                    url: '/change-lang',
//                    data: data,
//                    success: function (data) {
//                        if (data.success === true) {
//                            location.reload();
//                        }
//                    }
//                });
            })
        }
    }
}();



Lang.init();